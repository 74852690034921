/*
  * This file is part of con4gis,
  * the gis-kit for Contao CMS.
  *
  * @package   	con4gis
  * @version    6
  * @author  	con4gis contributors (see "authors.txt")
  * @license 	LGPL-3.0-or-later
  * @copyright 	Küstenschmiede GmbH Software & Design
  * @link       https://www.con4gis.org
  */
import {langConstantsGerman} from "./../../../../MapsBundle/Resources/public/js/c4g-maps-constant-i18n-de";
import {langConstantsEnglish} from "./../../../../MapsBundle/Resources/public/js/c4g-maps-constant-i18n-en";
import {projectEditorLang} from "./c4g-editor-i18n-de";
let mapsLang = {};

if (typeof mapData !== "undefined") {
  if (mapData.lang === "de") {
    mapsLang = langConstantsGerman;
  } else if (mapData.lang === "en") {
    mapsLang = langConstantsEnglish;
  } else {
    // fallback
    mapsLang = langConstantsGerman;
  }
}
export var langConstants = jQuery.extend(mapsLang, projectEditorLang);